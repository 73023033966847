
const imageReveal = () => {

  // For page load, have first image showing
  const firstPanel = document.querySelector('.show-image--img_1');

  if (!firstPanel) return false;
  
  firstPanel.classList.add('active');

  let image = document.querySelectorAll('.get-data');

  // Loop over all nav items
  for (let i = 0; i < image.length; i++) {

    // Hover over each item to initiate 
    image[i].addEventListener('mouseenter', () => {

      // Get value from data attribute
      const data = image[i].getAttribute('data-image');

      // Create unique identifier 
      const createAssociation = '.show-image--' + data;

      // Add active class to unique identifier 
      document.querySelector(createAssociation).classList.add('active');
      // document.querySelector(createAssociation).classList.toggle('active');

      // test
      // var el = document.querySelector(createAssociation);
      // console.log(el);
      // var one = el.previousElementSibling();
      // one.classList.remove("active");

    });

    // Remove cursor to end 
    image[i].addEventListener('mouseleave', () => {

      const data = image[i].getAttribute('data-image');

      const createAssociation = '.show-image--' + data;

      // Remove active class to unique identifier 
      document.querySelector(createAssociation).classList.remove('active');

      // Always show first slide image when nothing is being hovered
      firstPanel.classList.add('active');

    });

  };

 

}

export default imageReveal;