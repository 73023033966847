// Import Scss DO NO DELETE
import '../scss/main.scss';

// Import external dependencies
import 'custom-event-polyfill/polyfill.js';
// import 'imports-loader?define=>false!ScrollMagic/scrollmagic/uncompressed/plugins/animation.gsap';
// import 'imports-loader?define=>false!ScrollMagic/scrollmagic/uncompressed/plugins/debug.addIndicators';

// IE11 Polyfill for forEach
if(window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = Array.prototype.forEach;
}
if(window.HTMLCollection && !HTMLCollection.prototype.forEach) {
  HTMLCollection.prototype.forEach = Array.prototype.forEach;
}

// IE11 Polyfill for Element.remove()
Element.prototype.remove || (Element.prototype.remove = function() {
	if (this.parentNode!=null) this.parentNode.removeChild(this);
});

// Bundle module files on build
// https://medium.com/@svinkle/getting-started-with-webpack-and-es6-modules-c465d053d988
const requiredModules = require.context("./modules/", true, /\.(js)$/i);
requiredModules.keys().map(key => {
  requiredModules(key).default();
});