const cookiesBar = () => {

  const cookiesBar = document.querySelector('.js-cookies');

  if (!cookiesBar) return;

  const btn = cookiesBar.querySelector('.btn');

  btn.addEventListener('click', function () {
    setCookie('cookie_policy', true, 30);
    setTimeout(function () {
      cookiesBar.remove();
    }, 100);
  });

  function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    const expires = 'expires=' + d.toUTCString();
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
  }

}

export default cookiesBar;