import gsap from "gsap";
import ScrollTrigger from '../libs/ScrollTrigger.js';
gsap.registerPlugin(ScrollTrigger);

/**
 * ScrollTrigger Docs
 * https://greensock.com/docs/v3/Plugins/ScrollTrigger
 * https://greensock.com/st-get-started/
 */

const animationScroll = () => {

  const trigger = document.querySelectorAll('[data-scrollIn]');

  if (!trigger) return false;

  trigger.forEach((el, i) => {

      const tween = gsap.fromTo(el.querySelectorAll('h2, .subtitle, p, .btn, a, .cta-logo__logo, li'),
        {
          autoAlpha: 0, 
          // y: 10
        }, 
        {
          duration: 1, 
          autoAlpha: 1,
          // y: 0,
          ease:"Power1.easeIn", 
          stagger: {
            amount: 0.5, 
          }
        });

      ScrollTrigger.create({
        trigger: el,
        animation: tween,
        once: true,
        start: "top 75%", //when top of trigger passes 75% viewport height
        markers: false,
        
      });

    });
 

};

export default animationScroll;