import gsap from "gsap";

// Note: Below duplicated within loader.js
const animationLoad = () => {

  const animate = document.querySelector('[data-loadIn]');

  if (!animate) return false;

  const animateItems = animate.querySelectorAll('h1, .subtitle, .hero--link, .hero--inner__arrow, .hero--logo');

  gsap.fromTo(
    animateItems, 
    {
      autoAlpha: 0,
      y: 20
    },
    {
      duration: 1, 
      autoAlpha: 1,
      y: 0,
      ease: "power1.ease",
      stagger: {
        amount: 0.5, 
      },
      delay: 0.5
    })

};

export default animationLoad;