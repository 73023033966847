// const toggleNav = () => {

// };


const navigation = () => {

  const overlay = document.querySelector('.nav-overlay');
  const hamburger = document.querySelector('.hamburger');

  hamburger.addEventListener('click', (el) => {

    overlay.classList.toggle('is-active');
    hamburger.classList.toggle('is-active');

    const subMenu = document.querySelectorAll('.menu-item-has-children');

    for (let i = 0; i < subMenu.length; i++) {

      subMenu[i].addEventListener('click', (e) => {
        e.stopPropagation();
        // e.preventDefault();
        subMenu[i].classList.toggle('is-active');
      });

    }

  });

};

export default navigation;