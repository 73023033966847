import gsap from "gsap";

const loader = () => {

  const _loaderWrap = document.querySelector('.loader-wrapper .loader-wrapper__inner');
  const _loader = document.querySelector('#loader');
  const pattern = document.querySelectorAll('#loader svg #arch-path path');
  const text = document.querySelector('#loader svg #logo-text');

  if (!_loaderWrap) return false;

    _loaderWrap.style.opacity = 1;


    for (let i = 0; i < pattern.length; i++) {

      // Get total length of svg path
      let length = pattern[i].getTotalLength();

      pattern[i].style.strokeDasharray = length; // length;
      pattern[i].style.strokeDashoffset = -length; // -length

      // https://greensock.com/forums/topic/19100-svg-stroke-dashoffset-and-pathlength/
      gsap.set(pattern[i], {
        strokeDasharray: length,
      });

      gsap.fromTo(pattern[i], 5, {
        strokeDashoffset: length,
      },
        {
          strokeDashoffset: 0,
        });

        gsap.fromTo(pattern[i], 1, {
          fill: 'transparent',
        },
          {
            fill: '#111111',
            stroke: 0,
            delay: 1.2
          });
    
    }

    gsap.fromTo(text, 1, {
        autoAlpha: 0,
        fill: 'transparent'
      },
        {
          strokeDashoffset: 0,
          delay: 1,
          autoAlpha: 1,
          stroke: 0,
          fill: '#111111'
        });

  window.onload = function(e){ 
    // console.log('window loaded');

    setTimeout(() => {
      _loader.classList.add('loaded');

      const animate = document.querySelector('[data-loadIn]');

      if (!animate) return false;

      const animateItems = animate.querySelectorAll('h1, .subtitle, .hero--link, .hero--inner__arrow');

      gsap.fromTo(
        animateItems, 
        {
          autoAlpha: 0,
          y: 20
        },
        {
          duration: 1, 
          autoAlpha: 1,
          y: 0,
          ease: "power1.ease",
          stagger: {
            amount: 0.5, 
          },
          delay: 1 // 4.2
        })

    }, 3000 );
  }

}

export default loader;
