const mapboxgl = require('mapbox-gl/dist/mapbox-gl.js');

const map = () => {
 
  //  Need page url if statement here

  // console.log(window.location.origin);
  // console.log(window.location.href);
  // console.log(window.location.pathname);

  if (window.location.pathname === '/contact/') {

    mapboxgl.accessToken = 'pk.eyJ1IjoibG13ZCIsImEiOiJjazY4aWJqeWUwNTBpM25ybmlvd2Q5Y2tnIn0.9zz6eijo4wMjQThm4594lA';

    const location = new mapboxgl.Map({
      container: 'map',
      // style: 'mapbox://styles/mapbox/streets-v11',
      style: 'mapbox://styles/lmwd/ck68ip35b17c11img8nl5oha7',
      center: [-2.299353, 53.303592],
      zoom: 12 // 15.5
    });

    const geojson = {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [-2.299353, 53.303592],
          },
          properties: {
            title: 'Cpase',
            description: 'Boutique Health Club'
          }
        }
      ]
    };

    geojson.features.forEach(function (marker) {
      // create a HTML element for each feature
      var el = document.createElement('div');
      el.className = 'marker';

      // make a marker for each feature and add to the map
      new mapboxgl.Marker(el).setLngLat(marker.geometry.coordinates).addTo(location);
    });

    location.scrollZoom.disable();

  }

};

export default map;