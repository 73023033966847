const popup = () => {

  let popup = document.querySelectorAll('[data-popup-activator]');

  for (let i = 0; i < popup.length; i++) {

    // Loop over all popup activators
    popup[i].addEventListener('click', () => {

      // Get unique value from data-attribute 
      const dataValue = popup[i].getAttribute(['data-popup-activator']);

      // Activate popup with same unique data-attribute value
      document.querySelector('[data-popup="' + dataValue + '"]').classList.toggle('is-active');

      // Close popup 
      const close = document.querySelectorAll('.popup-modal .close');

      if (close) {
        for (let i = 0; i < close.length; i++) {
          close[i].addEventListener('click', () => {
            document.querySelector('[data-popup="' + dataValue + '"]').classList.remove('is-active');
          });
        }
      }

    });

  }
  
};
  
export default popup;