import Glide from '@glidejs/glide';

const carousel = () => {

  const carousel = document.querySelector('[data-carousel]');
  // const slide = document.querySelectorAll('li.glide__slide');

  if (carousel) {

    const glide = new Glide(carousel, {
      startAt: 0,
      perView: 3,
      type: 'slider',
      gap: 20,
      arrows: true,
      breakpoints: {
        930: {
          perView: 2
        },
        767: {
          perView: 1
        }
      }
    });

    // Hide buttlets and arrows when theres only one slide
    // if (slide.length <= 1) {
    //   document.querySelector('.glide__arrows').style.display = 'none';
    //   document.querySelector('.glide__bullets').style.display = 'none';
    // }
  
    glide.mount()
    
  }

};

export default carousel;